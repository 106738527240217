<template>
    <div>
        <!-- <div>
          <TranscriptPrint :schoolData="school" :studentData="student" :schoolYearsData="schoolYears" />
        </div> -->
        <hr />
        <div id="pdf-content">
            <VueHtml2pdf
                :show-layout="false"
                :float-layout="true"
                :paginate-elements-by-height="1400"
                :enable-download="true"
                :preview-modal="true"
                :pdf-quality="2"
                filename="transcript.pdf"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                margin="10px"
                ref="html2Pdf">
                <section slot="pdf-content">
                  <TranscriptPrint :schoolData="school" :studentData="student" :schoolYearsData="schoolYears" />
                </section>
            </VueHtml2pdf>
        </div>
        <div>
            <TranscriptInput 
              :schoolData="school"
              :studentData="student"
              :schoolYearsData="schoolYears" />
        </div>
        <div>
            <input type="button" value="Print" @click="generatePDF" />
        </div>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import TranscriptPrint from '@/components/TranscriptPrint.vue'
import TranscriptInput from '@/components/TranscriptInput.vue'

export default {
  components: {
    VueHtml2pdf,
    TranscriptPrint,
    TranscriptInput
  },
  data () {
    return {
      school: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: ''
      },

      student: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: ''
      },

      schoolYears: []
    }
  },
  methods: {
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
